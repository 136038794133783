<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-2">
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{ $t('shop.userName') }}</label>
                    <input
                        type="text"
                        v-model="model.user_name"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('user_name') }"
                        :placeholder="$t('shop.userName')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('user_name')"
                    >
                        {{ errors.first('user_name') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1 tw-w-full">
                    <label class="required">{{ $t('shop.password') }}</label>
                    <div class="tw-w-full">
                        <a-input-group compact>
                            <input
                                class="form-control"
                                :type="input_type"
                                v-model="model.password"
                                style="width: calc(100% - 41px)"
                                :class="{
                                    'is-invalid': errors.has('password')
                                }"
                                :placeholder="$t('shop.password')"
                            />
                            <ts-button
                                color="primary"
                                outline
                                @click.prevent="showPassword"
                            >
                                <i
                                    v-if="input_type === 'text'"
                                    class="toggle-password fas fa-eye"
                                ></i>
                                <i
                                    v-else
                                    class="toggle-password fa fa-fw fa-eye-slash"
                                ></i
                            ></ts-button>
                        </a-input-group>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{ $t('shop.confirm') }}</label>
                    <div class="tw-w-full">
                        <a-input-group compact>
                            <input
                                class="form-control"
                                :type="input_type_confirmation"
                                v-model="model.password_confirmation"
                                style="width: calc(100% - 41px)"
                                :class="{
                                    'is-invalid': errors.has(
                                        'password_confirmation'
                                    )
                                }"
                                :placeholder="$t('shop.confirm')"
                            />
                            <ts-button
                                color="primary"
                                outline
                                @click.prevent="showPasswordConfirmation"
                            >
                                <i
                                    v-if="input_type_confirmation === 'text'"
                                    class="toggle-password fas fa-eye"
                                ></i>
                                <i
                                    v-else
                                    class="toggle-password fa fa-fw fa-eye-slash"
                                ></i
                            ></ts-button>
                        </a-input-group>
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from 'form-backend-validation'
export default {
    name: 'shopUser',
    props: ['value', 'validate'],
    data () {
        return {
            model: {},
            errors: new Errors(),
            input_type: 'password',
            input_type_confirmation: 'password'
        }
    },
    created () {
        this.model = this.value
    },
    methods: {
        showPassword () {
            if (this.input_type === 'password') {
                this.input_type = 'text'
            } else {
                this.input_type = 'password'
            }
        },
        showPasswordConfirmation () {
            if (this.input_type_confirmation === 'password') {
                this.input_type_confirmation = 'text'
            } else {
                this.input_type_confirmation = 'password'
            }
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        },
        'model.phone_number': function ($value) {
            this.model.user_name = $value
        }
    }
}
</script>
