var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-space-y-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('shop.userName')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.user_name),expression:"model.user_name"}],staticClass:"form-control",class:{ 'is-invalid': _vm.errors.has('user_name') },attrs:{"type":"text","placeholder":_vm.$t('shop.userName')},domProps:{"value":(_vm.model.user_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "user_name", $event.target.value)}}}),(_vm.errors.has('user_name'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('user_name'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-y-1 tw-w-full"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('shop.password')))]),_c('div',{staticClass:"tw-w-full"},[_c('a-input-group',{attrs:{"compact":""}},[((_vm.input_type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password),expression:"model.password"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('password')
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"placeholder":_vm.$t('shop.password'),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model.password)?_vm._i(_vm.model.password,null)>-1:(_vm.model.password)},on:{"change":function($event){var $$a=_vm.model.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.model, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.model, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.model, "password", $$c)}}}}):((_vm.input_type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password),expression:"model.password"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('password')
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"placeholder":_vm.$t('shop.password'),"type":"radio"},domProps:{"checked":_vm._q(_vm.model.password,null)},on:{"change":function($event){return _vm.$set(_vm.model, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password),expression:"model.password"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('password')
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"placeholder":_vm.$t('shop.password'),"type":_vm.input_type},domProps:{"value":(_vm.model.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "password", $event.target.value)}}}),_c('ts-button',{attrs:{"color":"primary","outline":""},on:{"click":function($event){$event.preventDefault();return _vm.showPassword.apply(null, arguments)}}},[(_vm.input_type === 'text')?_c('i',{staticClass:"toggle-password fas fa-eye"}):_c('i',{staticClass:"toggle-password fa fa-fw fa-eye-slash"})])],1)],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('shop.confirm')))]),_c('div',{staticClass:"tw-w-full"},[_c('a-input-group',{attrs:{"compact":""}},[((_vm.input_type_confirmation)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password_confirmation),expression:"model.password_confirmation"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has(
                                    'password_confirmation'
                                )
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"placeholder":_vm.$t('shop.confirm'),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model.password_confirmation)?_vm._i(_vm.model.password_confirmation,null)>-1:(_vm.model.password_confirmation)},on:{"change":function($event){var $$a=_vm.model.password_confirmation,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.model, "password_confirmation", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.model, "password_confirmation", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.model, "password_confirmation", $$c)}}}}):((_vm.input_type_confirmation)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password_confirmation),expression:"model.password_confirmation"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has(
                                    'password_confirmation'
                                )
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"placeholder":_vm.$t('shop.confirm'),"type":"radio"},domProps:{"checked":_vm._q(_vm.model.password_confirmation,null)},on:{"change":function($event){return _vm.$set(_vm.model, "password_confirmation", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password_confirmation),expression:"model.password_confirmation"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has(
                                    'password_confirmation'
                                )
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"placeholder":_vm.$t('shop.confirm'),"type":_vm.input_type_confirmation},domProps:{"value":(_vm.model.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "password_confirmation", $event.target.value)}}}),_c('ts-button',{attrs:{"color":"primary","outline":""},on:{"click":function($event){$event.preventDefault();return _vm.showPasswordConfirmation.apply(null, arguments)}}},[(_vm.input_type_confirmation === 'text')?_c('i',{staticClass:"toggle-password fas fa-eye"}):_c('i',{staticClass:"toggle-password fa fa-fw fa-eye-slash"})])],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }